import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { ConfigData, initConfig } from './Config/Config';
import { initMockConfig } from './Config/MocksConfig';

const AppWrapper = React.lazy(() => import('./AppWrapper'));

function renderApp() {
  ReactDOM.render(
    <Suspense fallback={<div />}>
      <AppWrapper />
    </Suspense>,
    document.getElementById('root')
  );
}

(function init() {
  const CONFIGURATION_FILE = process.env.PUBLIC_URL + '/configuration.json';
  serviceWorker.unregister();
  axios
    .get<ConfigData>(CONFIGURATION_FILE, {
      headers: {
        'Cache-Control': 'no-store',
      },
    })
    .then(response => response.data)
    .then(initConfig)
    .then(fetchMockConfig)
    .then(renderApp);
})();

function fetchMockConfig(configuration: ConfigData) {
  if (configuration.isMockApp) {
    const MOCKS_CONFIGURATION_FILE = process.env.PUBLIC_URL + '/mocks-configuration.json';
    return axios
      .get(MOCKS_CONFIGURATION_FILE, {
        headers: {
          'Cache-Control': 'no-store',
        },
      })
      .then(response => response.data)
      .then(initMockConfig)
      .catch(() => console.log('no mocks-configuration file was found'));
  } else {
    return Promise.resolve();
  }
}
