export interface ConfigData {
  projectName: string;
  projectId: string;
  isHaveCustomReportsView: boolean;
  isMockApp: boolean;
  canConnectNewDataSource: boolean;
  hubspotAnalyticsAccountId: string | undefined;
  googleTagManagerContainerId: string | undefined;
  audienceSummaryExpirationInSeconds: number;
  hideAnalyticsTab: boolean | undefined;
  enableSequenceCampaign: boolean;
  showThingStatuses: boolean | undefined;
}

export const config: ConfigData = {
  projectName: '',
  projectId: '',
  isHaveCustomReportsView: false,
  isMockApp: false,
  canConnectNewDataSource: false,
  hubspotAnalyticsAccountId: undefined,
  googleTagManagerContainerId: undefined,
  audienceSummaryExpirationInSeconds: 3600,
  hideAnalyticsTab: true,
  enableSequenceCampaign: false,
  showThingStatuses: false,
};

export const initConfig = function(settings: ConfigData) {
  config.projectName = settings.projectName;
  config.projectId = settings.projectId;
  config.isHaveCustomReportsView = settings.isHaveCustomReportsView;
  config.isMockApp = settings.isMockApp;
  config.canConnectNewDataSource = settings.canConnectNewDataSource;
  if (settings.hubspotAnalyticsAccountId) {
    config.hubspotAnalyticsAccountId = settings.hubspotAnalyticsAccountId;
  }
  if (settings.googleTagManagerContainerId) {
    config.googleTagManagerContainerId = settings.googleTagManagerContainerId;
  }
  config.audienceSummaryExpirationInSeconds = settings.audienceSummaryExpirationInSeconds;
  config.hideAnalyticsTab = !!settings.hideAnalyticsTab;
  config.enableSequenceCampaign = settings.enableSequenceCampaign;
  config.showThingStatuses = !!settings.showThingStatuses;

  return config;
};
