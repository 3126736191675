interface MocksConfig {
  configuration: object;
}

export const mockConfig: MocksConfig = {
  // @ts-ignore
  configuration: undefined,
};

export const initMockConfig = function(configurationJson: object) {
  mockConfig.configuration = configurationJson;
};
